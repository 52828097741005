<template>
  <div class="credito mx-2">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title> Ventas a credito </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="6" sm="6">
                <v-card elevation="0" outlined>
                  <v-card-text>
                    <strong class="text-h5 font-weight-medium"></strong>
                    <v-row align="center" dense>
                      <v-col cols="12" md="12">
                        <v-btn
                          small
                          color="black"
                          dark
                          class="mb-4"
                          @click="dialog_form = true"
                          >Registrar Cliente</v-btn
                        >
                      </v-col>
                      <!-- Clientes -->
                      <v-col cols="12">
                        <v-tooltip top>
                          <template v-slot:activator="{ on, attrs }">
                            <v-autocomplete
                              v-model="id"
                              v-on="on"
                              v-bind="attrs"
                              dense
                              outlined
                              rounded
                              :loading="isLoading"
                              item-value="id"
                              :items="lista_clientes"
                              no-filter
                              label="Selecciona un cliente"
                              ref="clientes"
                              hide-no-data
                              @update:search-input="customFilterCliente"
                              @change="detailCliente()"
                            ></v-autocomplete>
                          </template>
                          <span
                            >Filtra tus clientes por nombre o cedula / ID</span
                          >
                        </v-tooltip>
                      </v-col>
                      <!-- Fecha factura-->
                      <v-col>
                        <v-menu
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="date"
                              outlined
                              rounded
                              dense
                              label="Fecha"
                              append-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            scrollable
                            v-model="date"
                            locale="es-co"
                            color="primary"
                            first-day-of-week="1"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <!-- Vencimiento -->
                      <v-col>
                        <v-menu
                          :close-on-content-click="false"
                          transition="scale-transition"
                          offset-y
                          min-width="290px"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="vence"
                              outlined
                              rounded
                              dense
                              label="Fecha vencimiento"
                              append-icon="mdi-calendar"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker
                            scrollable
                            v-model="vence"
                            locale="es-co"
                            color="primary"
                            first-day-of-week="1"
                          ></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12">
                        <v-textarea
                          rows="2"
                          no-resize
                          outlined
                          counter
                          auto-grow
                          v-model="observacion"
                          label="Observaciones"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- Detalle cliente -->
              <v-col cols="12" md="6" sm="6">
                <v-card elevation="0" outlined v-if="cliente">
                  <v-card-text>
                    <v-row align="center" no-gutters>
                      <v-col>
                        <strong style="color: #1976d2 !important">
                          {{ cliente.nombre }}
                        </strong>
                        <br />
                        <strong
                          >Cc:
                          <span style="color: #1976d2 !important">{{
                            cliente.cedula
                          }}</span>
                        </strong>
                        <br />
                        <strong
                          >Tel:
                          <span style="color: #1976d2 !important">{{
                            cliente.telefono
                          }}</span>
                        </strong>
                        <br />
                        <strong
                          >Puntos:
                          <span style="color: #1976d2 !important">{{
                            cliente.puntos
                          }}</span>
                        </strong>
                        <v-divider class="my-2"></v-divider>
                        <v-btn
                          :dark="!loading"
                          :color="!loading ? 'black' : 'gray'"
                          :loading="loading"
                          :disabled="loading"
                          @click="guardar()"
                        >
                          <v-icon left>mdi-upload</v-icon>
                          Guardar
                        </v-btn>
                      </v-col>
                      <v-col align="right">
                        <v-avatar>
                          <img
                            :src="
                              $store.state.centro.route +
                              'clientes/' +
                              cliente.foto
                            "
                          />
                        </v-avatar>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <!-- Productos -->
              <v-col cols="12" md="12" sm="12">
                <v-card elevation="0" outlined>
                  <v-card-title> Productos de venta </v-card-title>
                  <v-card-text>
                    <v-row no-gutters>
                      <v-col cols="12">
                        <v-autocomplete
                          class="mt-2"
                          style="width: 50%"
                          dense
                          outlined
                          rounded
                          v-model="producto"
                          append-icon="mdi-magnify"
                          item-text="filter"
                          :items="productosLista"
                          :filter="customFilter"
                          label="Selecciona un producto"
                          return-object
                          ref="p_venta"
                          @change="addItem"
                        ></v-autocomplete>
                        <v-simple-table dense>
                          <template v-slot:default>
                            <thead>
                              <tr>
                                <th></th>
                                <th>Codigo</th>
                                <th>Producto</th>
                                <th>Cantidad</th>
                                <th>Precio</th>
                                <th>Vendedor</th>
                                <th>Subtotal</th>
                                <th>IVA</th>
                                <th>Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr
                                v-for="(producto, index) in ventas"
                                :key="index"
                              >
                                <td>
                                  <v-tooltip top>
                                    <template v-slot:activator="{ on, attrs }">
                                      <v-btn
                                        icon
                                        color="error"
                                        v-on="on"
                                        v-bind="attrs"
                                        @click="deleteItem(index)"
                                      >
                                        <v-icon>mdi-delete</v-icon>
                                      </v-btn>
                                    </template>
                                    <span>Eliminar producto</span>
                                  </v-tooltip>
                                </td>
                                <td>
                                  {{ producto.codigo }}
                                </td>
                                <td>{{ producto.producto }}</td>
                                <td>
                                  <v-text-field
                                    style="width: 140px"
                                    type="number"
                                    class="mt-4"
                                    dense
                                    outlined
                                    rounded
                                    v-model.number="producto.cantidad"
                                    label="Gr / Uni"
                                    @change="updateVentas(index)"
                                  ></v-text-field>
                                </td>
                                <td>
                                  <v-text-field
                                    style="width: 140px"
                                    type="number"
                                    class="mt-4"
                                    dense
                                    outlined
                                    rounded
                                    v-model.number="producto.valor"
                                    label="Precio"
                                    @change="updateVentas(index)"
                                  ></v-text-field>
                                </td>
                                <td>
                                  <v-autocomplete
                                    class="mt-4"
                                    style="width: 155px"
                                    dense
                                    outlined
                                    rounded
                                    v-model="producto.profesional_id"
                                    append-icon="mdi-magnify"
                                    item-text="nombre"
                                    item-value="id"
                                    :items="profesionales"
                                    :filter="customFilter"
                                    label="Selecciona un profesional"
                                    @change="addVendedor"
                                  ></v-autocomplete>
                                </td>
                                <td>{{ producto.subtotal | currency }}</td>
                                <td>{{ producto.iva | currency }}</td>
                                <td>{{ producto.total | currency }}</td>
                              </tr>
                            </tbody>
                          </template>
                        </v-simple-table>
                      </v-col>
                    </v-row>
                  </v-card-text>
                </v-card>
              </v-col>
              <v-col cols="12" md="6" sm="6" offset="6">
                <v-card elevation="0" outlined>
                  <v-card-text>
                    <v-simple-table dense>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <th>Subtotal</th>
                            <td>
                              {{ subtotal | currency }}
                            </td>
                          </tr>
                          <tr>
                            <th>IVA</th>
                            <td>{{ iva | currency }}</td>
                          </tr>
                          <tr>
                            <th>Total</th>
                            <td>{{ total | currency }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-dialog v-model="dialog" persistent max-width="600px" scrollable>
          <v-card>
            <v-card-title class="headline"> Pago </v-card-title>

            <v-card-text>
              <set-document
                v-if="documentoElectronico"
                :electronico="electronico.electronico"
                :factura_id="electronico.factura_id"
                v-on:setCancel="setCancel($event)"
              />
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="error" text @click="closePago()" v-if="cancel_btn">
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialog_form" persistent scrollable max-width="600px">
          <v-card>
            <v-card-title>
              <span class="headline">Crear cliente</span>
            </v-card-title>
            <v-card-text
              ><v-row>
                <v-col cols="12" md="12" sm="12">
                  <formComponent :list="form" ref="clienteForm" />
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn text @click="cancelForm()" color="primary">
                Cancelar
              </v-btn>
              <v-btn
                :dark="!loading"
                :color="!loading ? 'black' : 'gray'"
                :loading="loading"
                :disabled="loading"
                @click="registerCliente()"
              >
                Crear
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import setDocument from "../../../components/SetDocument.vue";
import formComponent from "../../../components/Form.vue";
import * as moment from "moment";
export default {
  name: "credito",
  components: {
    setDocument,
    formComponent,
  },
  data: () => ({
    id: null,
    search: "",
    // Call
    facturacion: null,
    clientes: [],
    productos: [],
    profesionales: [],
    ventas: [],

    // Render
    isLoading: false,
    loading: false,
    cliente: null,
    date: moment().format("YYYY-MM-DD"),
    vence: moment().add(1, "month").format("YYYY-MM-DD"),
    observacion: "",
    producto: null,
    print_post: false,
    cancel_btn: true,
    factura_id: null,
    documentoElectronico: false,
    electronico: null,
    dialog: false,
    dialog_form: false,
    form: {
      ref: "clientesRegister",
      list: [
        {
          grid: {
            cols: 12,
            md: 6,
            sm: 6,
          },
          input: {
            type: "input",
            input_type: "number",
            label: "ID / CC",
          },
          validation: {
            valid: true,
            rules: "required",
          },
          id: "cedula",
          model: "",
          show: true,
        },
        {
          grid: {
            cols: 12,
            md: 6,
            sm: 6,
          },
          input: {
            type: "input",
            input_type: "text",
            label: "Cliente",
          },
          validation: {
            valid: true,
            rules: "required",
          },
          id: "nombre",
          model: "",
          show: true,
        },
        {
          grid: {
            cols: 12,
            md: 6,
            sm: 6,
          },
          input: {
            type: "input",
            input_type: "text",
            label: "Correo",
          },
          validation: {
            valid: true,
            rules: "required|email",
          },
          id: "email",
          model: "",
          show: true,
        },
        {
          grid: {
            cols: 12,
            md: 6,
            sm: 6,
          },
          input: {
            type: "input",
            input_type: "text",
            label: "Dirección",
          },
          validation: {
            valid: false,
            rules: "",
          },
          id: "direccion",
          model: "",
          show: true,
        },
        {
          grid: {
            cols: 12,
            md: 4,
            sm: 4,
          },
          input: {
            type: "input",
            input_type: "number",
            label: "Telefono",
          },
          validation: {
            valid: false,
            rules: "",
          },
          id: "telefono",
          model: "",
          show: true,
        },
        {
          grid: {
            cols: 12,
            md: 4,
            sm: 4,
          },
          input: "picker",
          menu: {
            model: false,
            label: "Cumpleaños",
          },
          validation: {
            valid: false,
          },
          id: "fecha",
          model: moment().format("YYYY-MM-DD"),
          show: true,
        },
        {
          grid: {
            cols: 12,
            md: 4,
            sm: 4,
          },
          input: "select",
          select: {
            label: "Genero",
            items: [
              { text: "Masculino", value: "m" },
              { text: "Femenino", value: "f" },
              { text: "Otros", value: "o" },
            ],
            hint: "Selecciona un genero",
            multiple: false,
          },
          validation: {
            valid: true,
            rules: "required",
          },
          id: "genero",
          model: "f",
          show: true,
        },
      ],
    },
  }),
  methods: {
    loadFacturacion() {
      const body = {
        route: "/facturacion_data",
      };
      this.$store.dispatch("axios_get", body).then((response) => {
        if (response.data.success) {
          this.facturacion = response.data.data;
          this.observacion = this.facturacion.notas;
        }
      });
    },
    loadClientesLite() {
      this.clientes = [];
      this.isLoading = true;
      const body = {
        route: `/clientes_lite`,
        params: {
          search: this.search,
        },
      };
      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) {
            this.clientes = response.data.data;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    detailCliente() {
      const body = {
        route: "/detalle_cliente",
        params: {
          id: this.id,
        },
      };
      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) {
            this.cliente = response.data.data;
          }
        })
        .finally(() => {
          this.$refs.clientes.reset();
        });
    },
    loadProductos() {
      const body = {
        route: "/productos_activos",
      };
      this.$store.dispatch("axios_get", body).then((response) => {
        if (response.data.success) {
          this.productos = response.data.data;
        }
      });
    },
    loadProfesionales() {
      const body = {
        route: "/profesionales_disponibles",
      };
      this.$store.dispatch("axios_get", body).then((response) => {
        if (response.data.success) {
          this.profesionales = response.data.data;
        }
      });
    },
    customFilter(item, queryText) {
      let type = "";
      const removeAccents = (str) => {
        return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
      };

      if (item.text != undefined && type == "") type = "text";

      if (item.servicio != undefined && type == "") type = "servicio";

      if (item.nombre != undefined && type == "") type = "nombre";

      if (item.filter != undefined && type == "") type = "filter";

      const text = removeAccents(queryText);
      const textOne = removeAccents(item[type]).toLowerCase();
      const searchText = text.toLowerCase();
      return textOne.indexOf(searchText) > -1;
    },
    customFilterCliente(item) {
      this.search = item;
      if (this.search != null || this.search != undefined) {
        if (this.search != "") this.loadClientesLite();
      }
    },
    addItem(item) {
      let profesional_id = null;

      if (this.ventas.length > 0) {
        profesional_id = this.ventas[this.ventas.length - 1].profesional_id;
      }

      let iva = 0;
      if (this.facturacion.regimen != "simplificado") {
        iva = parseFloat(item.iva_venta);
      }
      let body = {
        id: item.id_activo,
        codigo: item.codigo,
        producto: item.producto,
        valor: parseFloat(item.venta),
        iva_venta: iva,
        cantidad: 1,
        subtotal: parseFloat(
          (parseFloat(item.venta) / ((100 + iva) / 100)).toFixed(2)
        ),
        iva: parseFloat(
          (
            ((parseFloat(item.venta) / ((100 + iva) / 100)) * iva) /
            100
          ).toFixed(2)
        ),
        total: parseFloat(item.venta),
        profesional_id,
      };
      this.ventas.push(body);
      this.producto = null;
      this.$refs.p_venta.reset();
    },
    deleteItem(index) {
      this.ventas.splice(index, 1);
    },
    updateVentas(index) {
      let venta = this.ventas[index];
      venta.cantidad = venta.cantidad == "" ? 0 : venta.cantidad;
      venta.valor = venta.valor == "" ? 0 : venta.valor;
      venta.subtotal = parseFloat(
        (
          (parseFloat(venta.valor) / ((100 + venta.iva_venta) / 100)) *
          venta.cantidad
        ).toFixed(2)
      );
      venta.iva = parseFloat(
        (
          (((parseFloat(venta.valor) / ((100 + venta.iva_venta) / 100)) *
            venta.iva_venta) /
            100) *
          venta.cantidad
        ).toFixed(2)
      );
      venta.total = venta.valor * venta.cantidad;
    },
    closePago() {
      this.dialog = false;
      this.$router.push("/detallefactura/" + this.factura_id);
    },
    setCancel(input) {
      this.cancel_btn = input;
    },
    guardar() {
      this.loading = true;
      if (this.cliente) {
        if (this.ventas.length > 0) {
          const fecha_actual = moment().format("YYYY-MM-DD");
          if (!moment(this.date).isAfter(fecha_actual)) {
            let body = {
              route: "/venta_credito",
              data: {
                factura: {
                  datos_facturacion: this.facturacion.id,
                  cita_id: null,
                  cliente_id: this.cliente.id,
                  cliente: this.cliente.nombre,
                  cc_cliente: this.cliente.cedula,
                  telefono: this.cliente.telefono,
                  direccion: this.cliente.direccion,
                  propina: 0,
                  subtotal: this.subtotal,
                  iva: this.iva,
                  total: this.total,
                  efectivo: 0,
                  transfer: 0,
                  datafono: 0,
                  puntos: 0,
                  tipo: "Venta credito",
                  observacion: this.observacion == "" ? null : this.observacion,
                  fecha: this.date,
                  vencimiento: this.vence,
                },
                salidas: [],
                created: moment().format("YYYY-MM-DD HH:mm:ss"),
                time: moment().format("HH:mm:ss"),
              },
            };
            this.ventas.forEach((venta) => {
              let p_venta = {
                servicio_id: null,
                producto_id: venta.id,
                salida: venta.producto,
                profesional_id: venta.profesional_id,
                categoria: "venta",
                cantidad: venta.cantidad,
                subtotal: venta.subtotal,
                iva: venta.iva,
                total: venta.total,
              };
              body.data.salidas.push(p_venta);
            });
            this.$store
              .dispatch("axios_post", body)
              .then((response) => {
                if (response.data.success) {
                  this.factura_id = response.data.data.id;
                  this.documentoElectronico =
                    response.data.data.electronico.authorization;
                  if (this.documentoElectronico) {
                    this.cancel_btn = false;
                    this.electronico = {
                      electronico: response.data.data.electronico,
                      factura_id: this.factura_id,
                    };
                    this.dialog = true;
                  } else {
                    this.$router.push("/detallefactura/" + this.factura_id);
                  }
                }
              })
              .catch((error) => {
                let alert = {
                  alert: true,
                  color: "error",
                  text: error.response.data.data,
                };
                this.$store.dispatch("setAlert", alert);
              })
              .finally(() => (this.loading = false));
          } else {
            let alert = {
              alert: true,
              color: "error",
              text: "No puedes registrar facturas posteriores a la fecha actual",
            };
            this.$store.dispatch("setAlert", alert);
            this.loading = false;
          }
        } else {
          let alert = {
            alert: true,
            color: "error",
            text: "No se ingresaron valores a la factura",
          };
          this.$store.dispatch("setAlert", alert);
          this.loading = false;
        }
      } else {
        let alert = {
          alert: true,
          color: "error",
          text: "No se selecciono un cliente",
        };
        this.$store.dispatch("setAlert", alert);
        this.loading = false;
      }
    },
    addVendedor(value) {
      this.ventas.forEach((venta) => {
        if (!venta.profesional_id) {
          venta.profesional_id = value;
        }
      });
    },
    registerCliente() {
      this.$refs.clienteForm.validate().then((res) => {
        if (res) {
          this.loading = true;
          let body = {
            route: "/register_cliente",
            data: {
              data: this.$store.getters.validation_form(this.form.list),
            },
          };
          body.data.data.telefono =
            body.data.data.telefono == "" ? "0000000" : body.data.data.telefono;
          body.data.data.direccion =
            body.data.data.direccion == ""
              ? "No registra"
              : body.data.data.direccion;
          this.$store
            .dispatch("axios_post", body)
            .then((response) => {
              if (response.data.success) {
                let alert = {
                  alert: true,
                  color: "success",
                  text: response.data.data,
                };
                this.$store.dispatch("setAlert", alert);
                this.cancelForm();
              }
            })
            .catch((error) => {
              this.loading = false;
              let alert = {
                alert: true,
                color: "error",
                text: error.response.data.data,
              };
              this.$store.dispatch("setAlert", alert);
            });
        }
      });
    },
    cancelForm() {
      this.loading = false;
      this.dialog_form = false;
      this.setForm();
      this.$refs.clienteForm.reset();
    },
    setForm() {
      this.form.list.forEach((item) => {
        if (item.id == "puntos" || item.id == "estado") {
          item.show = false;
        }
        if (item.id != undefined) {
          item.model = "";
          if (item.id == "fecha") {
            item.model = moment().format("YYYY-MM-DD");
          }

          if (item.id == "genero") {
            item.model = "f";
          }
        }
      });
    },
  },
  created() {
    // this.loadClientesLite();
    this.loadProductos();
    this.loadProfesionales();
    this.loadFacturacion();
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["recepcion"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    lista_clientes() {
      return this.clientes.map((cliente) => {
        return {
          text: `${cliente.nombre} [${cliente.cedula}]`,
          nombre: cliente.nombre,
          cedula: cliente.cedula,
          id: cliente.id,
        };
      });
    },
    productosLista() {
      const productos = this.productos.map((producto) => {
        producto.compra = parseFloat(producto.compra);
        producto.venta = parseFloat(producto.venta);
        producto.iva_compra = parseFloat(producto.iva_compra);
        producto.iva_venta = parseFloat(producto.iva_venta);
        producto.inventario = parseInt(producto.inventario);
        let data = producto.producto + " [" + producto.codigo + "]";
        Object.assign(producto, { filter: data });
        return producto;
      });
      return productos.filter((producto) => {
        return producto.inventario == 1;
      });
    },
    subtotal() {
      return parseFloat(
        this.ventas
          .reduce((index, item) => {
            return index + item.subtotal;
          }, 0)
          .toFixed(2)
      );
    },
    iva() {
      return parseFloat(
        this.ventas
          .reduce((index, item) => {
            return index + item.iva;
          }, 0)
          .toFixed(2)
      );
    },
    total() {
      return parseFloat((this.subtotal + this.iva).toFixed(2));
    },
  },
  watch: {
    id(val) {
      if (val != undefined || val != null) this.$refs.clientes.reset();
      return;
    },
    date(val) {
      this.vence = moment(val, "YYYY-MM-DD")
        .add(1, "month")
        .format("YYYY-MM-DD");
      return this.date;
    },
  },
};
</script>
